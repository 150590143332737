document.addEventListener('DOMContentLoaded', function() {



	document.addEventListener('click', function(event) {
	    var carritoHeaderClick = document.querySelector('.carrito-header');
	    if (!carritoHeaderClick.contains(event.target) ) {
	        if (document.querySelector('.carrito-header').classList.contains('active')) document.querySelector('.carrito-header').classList.toggle('active');
	    }
	});


	var comunidadModal = document.getElementById('comunidadModal');
	if(comunidadModal){
		comunidadModal.style.display = 'block'
	}



    var params = new URLSearchParams(window.location.search);
    var parametroE = params.get('e');
    var parametroW = params.get('w');
    if (parametroE !== null || parametroW !== null) {
		alertModal = document.getElementById('alert')
		alertModal.classList.add('show')
		alertModal.style.display = 'block'
	}

	closeAlert1 = document.getElementById('closeAlert1')
	closeAlert2 = document.getElementById('closeAlert2')

	closeAlert1.addEventListener('click', cerrarModalAlert)
	closeAlert2.addEventListener('click', cerrarModalAlert)

	function cerrarModalAlert() {
		var currentUrl = window.location.href
		var url = new URL(currentUrl)
		var parametroError = url.searchParams.get("e");
		url.searchParams.delete('e')
		url.searchParams.delete('w')
		history.replaceState(null, null, url.toString());
		alertModal = document.getElementById('alert')
		alertModal.classList.remove('show')
		alertModal.style.display = 'none'
	}



	function puntoCarrito(){
		document.getElementById('punto-carrito').classList.remove('d-none');
        var carritoHeader = document.querySelector('.carrito-header')
        carritoHeader.classList.add('active')		
	}
	window.puntoCarrito = puntoCarrito;

});
